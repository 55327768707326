
.tile-contact {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 10px;
}

.numere-telefon-electrician {
    display: flex;
    flex-flow: column nowrap;
}

.contact-electrician-sibiu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.suna-electrician-sibiu-btn,
.whatsapp-electrician-sibiu-btn {
    border-radius: 4px;
    border: 1px solid #001a9e;
    cursor: pointer;
    text-align: left;
    padding: 5px 10px 5px 0px;
}

.suna-electrician-sibiu-btn { 
    background-image: linear-gradient(#516eff, #002aff);
}
.suna-electrician-sibiu-btn:hover {
    background-image: linear-gradient(#6982fd, #002aff);
}

.whatsapp-electrician-sibiu-btn { 
    background-image: linear-gradient(#516eff, #002aff);
}

.whatsapp-electrician-sibiu-btn:hover {
    background-image: linear-gradient(#6982fd, #002aff);
}

.suna-electrician-sibiu-btn a,
.whatsapp-electrician-sibiu-btn a {
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
}
.suna-electrician-sibiu-btn .telefon-electrician,
.whatsapp-electrician-sibiu-btn .whatsapp-electrician {
    display: inline-block;
    width: 40px;
    vertical-align: middle;
}