@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
    font-family: 'Roboto', sans-serif;
}
body {
    background-color: #74d4a2;
}
#paginaElectrician {
    margin: 0 auto;
    max-width: 1024px;
}